import { configureStore } from "@reduxjs/toolkit";
import ModalReducer from "./slice/ModalSlice"
import productReducer from "./slice/ProductSlice"
import LoadingReducer from "./slice/LoadingSlice"
export const store = configureStore({
    reducer:{
        Modal:ModalReducer,
        isSpinner:LoadingReducer,
        product:productReducer
    }
})