import React, { useState } from 'react'
import { Carousel } from "antd";
import { Help } from "../utils/utils";
import PlaceIcon from "@mui/icons-material/Place";
import { NavLink} from "react-router-dom";
import instance,{lienImage} from "../api/api";
import {Pagination} from 'antd';
const contentStyle = {
    margin: 0,
    height: "400px",
    color: "#fff",
    textAlign: "center",
    background: "#364d79",
    width: "100%",
    objectFit: "cover",
  };
function AffichePropriete(props) {
    
    let {propriete,getVilleSearch,lien,isLoading} = props
    const [current,setCurrent]=useState(1);
    const [pageSize]=useState(6);
    const startIndex = (current-1)*pageSize
    const endIndex = startIndex + pageSize;
    const currentData = propriete.slice(startIndex,endIndex);
     
    // const onChange = (currentSlide) => {
    //     console.log(currentSlide);
    //   };
      const onChangeCurrent = (page)=>{
        // console.log(page)
        setCurrent(page)
      }
    
      const seeResidence = async (id) => {
        try {
          await instance.post("addOneOnVue/" + id);
          // //console.log(response);
        } catch (error) {
          console.log(error);
        }
      };
  return (
    <section className="container-fluid my-4">
        <div className="all-conteneur">
          {currentData.length > 0 ? (
            currentData
              .filter((el) => el?.commune?.ville?.libelle.includes(getVilleSearch))
              .map((item,index) => (
                <div className="conteneur-residence" key={index}>
                <NavLink to={"/detail/residence/" + item.id} 
                style={{ 
                  color:"black",
                  textDecoration: 'none'
              }}
                onClick={() => {
                      seeResidence(item.id);
                    }}>
                  <div className="conteneur_image">
                    <Carousel 
                    autoplay
                    draggable
                    // afterChange={onChange}
                    >
                      {item.images.map((img) => (
                        <div key={img.id}>
                          <img
                            src={lienImage + img.path}
                            alt={img.path}
                            style={contentStyle}
                          />
                        </div>
                      ))}
                    </Carousel>
                   <div className='mx-2 p-3 d-flex align-items-center justify-content-between'>
                    <div>
                   <span
                    className="position-absolute badge bg-danger status"
                    style={{
                      background: item.activation === 2 ? "crimson" : null,
                    }}
                  >
                    {item.activation === 2 ? "Occupée" : ""}
                  </span>
                  <span className="titre_bien d-block mt-1">
                    {item.piece} Pièce{item.piece > 1 ? "s" : ""}
                  </span>
                  <span className="d-block mt-1" >{item.categorie.libelle}</span>
                  <span className="d-block mt-1"> {Help.getPrice(item.prix)} 
                  Fcfa {item.categorie.libelle.includes('residence') ?
                   '/jour':'/mois'}</span>
                   
                  <span className="d-block mt-1">
                    <PlaceIcon style={{ fontSize: "1em" }} />
                    {item.commune.ville.libelle},{item.commune.libelle}
                  </span>
                  </div>
                  {/* <div className="flex-1">
                  <NavLink
                    to={"/detail/residence/" + item.id}
                    onClick={() => {
                      seeResidence(item.id);
                    }}
                    className="see_prop"
                  >
                    Voir plus
                  </NavLink>
                </div> */}
                   </div>
                    
                  
                  </div>
                </NavLink>
                </div>
              ))
          ) : (
            <div className="text-center fw-bold">
              {isLoading ? "Chargement..." : "Pas de Biens disponibles."}
            </div>
          )}
    
        </div>
     
        {(currentData.length > 0 && currentData.filter((el) =>
              el?.commune?.ville?.libelle.includes(getVilleSearch)
            ).length === 0) ? (
              <div className="col-lg-12 fw-bold text-center">
                <p>Pas de bien trouvé.</p>
              </div>):  currentData.length > 0 && <Pagination  
            style={{textAlign:"center",padding:"1.5em"}}
            current={current} 
            onChange={onChangeCurrent}
             total={propriete.length}
             pageSize={pageSize}
             align="center"
             responsive
             />}
      </section>
  )
}

export default AffichePropriete