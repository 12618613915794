import React from "react";
// import { IconButton } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
// import MenuItem from "@mui/material/MenuItem";
// import Menu from "@mui/material/Menu";
// import { NavLink, useNavigate } from "react-router-dom";
// import { AuthContext } from "../context/AuthContext";
// import instance from "../api/api";
import { CiHome } from "react-icons/ci";

function Header() {
  // const { user, logOut } = useContext(AuthContext);
  // const navigate = useNavigate();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handleMenu = (e) => {
  //   setAnchorEl(e.currentTarget);
  // };

  // const handleClose = (e) => {
  //   setAnchorEl(null);
  // };
  // const seDeconnecter = async () => {
  //   try {
  //     await instance.post("logout");
  //     logOut();
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };
  // const authGoogle = async ()=>{
  //   try{
  //  const response = await instance.get('google/redirect')
  //  console.log("response",response)
  //  window.open(response.data.url,"example",{
  //   default: 'width=300,height=300,scrollbars=yes'
  // })
  // localStorage.setItem('social_auth','google')
  //   }catch(error){
  //     console.log(error);
  //   }
  // }
  // const authFacebook = async()=>{
  //   try{
  //     const response = await instance.get('facebook/redirect')
  //     console.log("response",response)
  //     window.open(response.data.url,"_self")
  //    localStorage.setItem('social_auth','facebook')
  //      }catch(error){
  //        console.log(error);
  //      }
  // }
  return (
    <div className="d-flex justify-content-between align-items-center
     p-2 conteneur-header">
      <h4 className="mx-1 d-flex align-items-center">
         <CiHome />Centre Commercial K.P
         </h4>
      {/* <div className="mx-4 d-flex align-items-center">
        {user && user.nom ? (
          <div className="d-flex align-items-center">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <span className="mx-1">{user.nom}</span>{" "}
              <Avatar alt={user.nom} src="/static/images/avatar/1.jpg" />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate("/tableau_bord")}>
                Tableau de bord
              </MenuItem>
              <MenuItem
                onClick={() => {
                  seDeconnecter();
                }}
              >
                {" "}
                Déconnexion
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <div className="d-flex align-items-center gap-2">
            <NavLink to="/connexion" className="see_prop fw-bold">
              Connexion
            </NavLink>
            <button className="btn bg-danger" onClick={authGoogle}>Login google</button>
            <button className="btn bg-primary" onClick={authFacebook}>Login Facebook</button>
          </div>
        )}
      </div> */}
    </div>
  );
}

export default Header;
