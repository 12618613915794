import dayjs from "dayjs";
import Compressor from 'compressorjs';
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export function diffForHumans(timestamp) {
    dayjs.updateLocale("en", {
      relativeTime: {
        future: "dans %s",
        past: "%s",
        s: "quelques secondes",
        m: "1 minute",
        mm: "%d minutes",
        h: "1 heure",
        hh: "%d heures",
        d: "1 jour",
        dd: "%d jours",
        M: "1 mois",
        MM: "%d mois",
        y: "1 an",
        yy: "%d ans",
      },
    });
    return dayjs(timestamp).fromNow();
}
export const Help = {
  getPrice(value){
    return Intl.NumberFormat("de-DE").format(value)
   },
   getPropriete(value){
    let tableauPiece = [];
    value.forEach((item) => {
    tableauPiece.push(item.piece);
  });
    return tableauPiece;
   },
   getTypeBien(value){
    let type = []
    value.forEach(item=>{
      type.push(item.categorie.libelle);
    })
    let newType = [...new Set(type)]
    return newType;
   },
   getMaxValueInTableau(value){
    return value.reduce((max, produit) => {
      return produit.prix > max.prix ? produit : max;
  }, value[0]);
   },
   getMinValueInTableau(value){
    return value.reduce((min, produit) => {
      return produit.prix < min.prix ? produit : min;
  }, value[0]);
   },
  handleFileArrayUpload(event){
    const files = event.target.files;
    if (!files || files.length === 0) return;
  
    Array.from(files).forEach((file) => {
      new Compressor(file, {
        quality: 0.6,
        mimeType: 'image/webp',
        success: (webpFile) => {
          console.log(webpFile);
         return webpFile
        },
        error: (err) => {
          console.error('Erreur de conversion:', err.message);
        },
      });
    });
  },
  handleFileUpload(event){
    const file = event.target.files[0];
    if (!file) return;

    // Conversion vers WebP
    new Compressor(file, {
      quality: 0.6,
      mimeType: 'image/webp',
      success: (webpFile) => {
        console.log(webpFile)
      },
      error: (err) => {
        console.error('Erreur de conversion:', err.message);
      },
    });
  }
}

