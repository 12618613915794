import React from 'react';
import { Dialog } from 'primereact/dialog';
import { useSelector, useDispatch } from "react-redux";
import {switchVisible} from "../../store/slice/ModalSlice"
export default function DialogProduct({libelleHeader,children}) {
  const dispatch = useDispatch();
  const isVisible = useSelector(
    (state) => state.Modal.visible
  );
  return (
    <div className="card flex justify-content-center">
    
    <Dialog header={libelleHeader} 
    visible={isVisible} 
    style={{ width: '95vw' }}
     onHide={() => dispatch(switchVisible(false))}>
        {children}
    </Dialog>
</div>
  );
}
