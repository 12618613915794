import React from 'react';
import { Spin } from 'antd';
export default function Loading() {
  return (
    <div style={{display:'flex',
    justifyContent:'center',
    alignItems:'center',
    placeContent:'center',
    position:'fixed',
    top:0,
    left:0,
    right:0,
    bottom:0,
    width:"100%",
    height:"100%",
    zIndex:999999999999999,
    background:'#00000085'
    }}>
    <Spin size="large"/>
    </div>
  );
}
