// import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState,useContext } from "react";
import { SpinLoading } from 'antd-mobile'
// import ScreemSearch from "../feature/ScreemSearch";
// import { SearchContext } from "../context/SearchContext";
import Loading from '../pages/Loading/Loading'
import Toast from "../pages/Notifications/Toast"
import { Tabs } from 'antd-mobile'
import instance, { lien } from "../api/api";
import { Help } from "../utils/utils";
import AffichePropriete from "../feature/AffichePropriete";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import Header from "../feature/Header";
import { Modal } from "antd";
import { Slider } from "antd-mobile";
import { AuthContext } from '../context/AuthContext';
import { Dialog } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import HomeProducts from "./Products/HomeProducts";
import { useSelector } from "react-redux";

let maxSlider = "";
let minSlider = "";
function Home() {
  const isToast = useSelector(state=>state.product.isToast)
  const isSpinner = useSelector(state=>state.isSpinner.isSpinnerVisible)
  const navigate = useNavigate();
  const {login}=useContext(AuthContext);
  const [villes, setVilles] = useState([]);

  const [propriete, setPropriete] = React.useState([]);
  const [proprieteFilter,setProprieteFilter] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allCommune, setAllCommune] = useState([]);
  const [getVilleSearch, setGetVilleSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valuePrixMax, setValuePrixMax] = useState(null);
  const [valuePrixMin, setValuePrixMin] = useState(null);
  const [isLoadingSearch,setIsLoadingSearch]=useState(false)
  const toastValue = (value) => {
    setValuePrixMax(value[1]);
    setValuePrixMin(value[0]);
  };
  const [filterProp, setFilterProp] = useState({
    pieceFilter: 1,
  });
  const getResultat = ()=>{
    setIsLoadingSearch(true)
    const data = {
      prixMin:Number(valuePrixMin),
      prixMax:Number(valuePrixMax),
      nbrePiece:Number(filterProp.pieceFilter)
    }
    setTimeout(()=>{
      const elementFilter = proprieteFilter.filter(item=>(item.prix >= data.prixMin && item.prix < data.prixMax) && (data.nbrePiece === item.piece) )
      setPropriete(propriete => propriete = elementFilter)
      handleOk()
      setIsLoadingSearch(false)
    },2000)
  
  }


  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const handleGoogleCallBack = async()=>{
    const URL_PARAMS = new URLSearchParams(window.location.search);
    console.log("URL_PARAMS",URL_PARAMS)
    const code = URL_PARAMS.get('code')
    const SOCIAL_NAME = localStorage.getItem('social_auth')
    const ROUTE  = SOCIAL_NAME === 'google' ? `auth/google/callback?code=${code}`:`auth/facebook/callback?code=${code}`
    if(code){
      try{
        const response = await instance.get(ROUTE)
        console.log("response.data",response.data)
        const {access_token,data}=response.data;
        
        login(data,access_token)
        if(response.data.status){
          setIsLoading(false)
            Dialog.alert({
            content: 'Connecté',
            confirmText:"OK"
             })
         navigate("/")
         localStorage.removeItem('social_auth')
        }
       }catch(error){
        console.log(error)
        localStorage.removeItem('social_auth')
       }
    }else{
      return;
    }
  }
  useEffect(()=>{
   
    handleGoogleCallBack();
  },[])

  useEffect(() => {
    const getAllPropriete = async () => {
      setIsLoading(true);
      try {
        const response = await instance.get("allAnnonces");
        const responseVille = await instance.get("allVille");
        const responseCommune = await instance.get("allCommune");
         if(response?.data?.data.length){
          const {prix} = Help.getMaxValueInTableau(response?.data?.data)
        setValuePrixMax(prix)
        const min = Help.getMinValueInTableau(response.data.data)
        setValuePrixMin(min.prix)
        minSlider = min.prix;
        maxSlider = prix;
         }
        
        if (response.data.status) {
          setPropriete(response.data.data);
          setProprieteFilter(response.data.data)
          setVilles(responseVille.data.data);
          setAllCommune(responseCommune.data.data);
          // setFilterCommune(responseCommune.data.data);
          
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getAllPropriete();
  }, []);

  const filtrePropriete = (idElement) => {
    const newTab = [];
    const allRange = [];
    propriete.forEach((element) => {
      if (element.commune.ville.libelle === idElement) {
        newTab.push(element.prix);
      } else {
        allRange.push(element.prix);
      }
    });
  };

  const getLibelleVille = (e) => {
    setGetVilleSearch(e.target.id);

    // setFilterCommune(
    //   allCommune.filter((item) => item.ville.libelle.includes(e.target.id))
    // );
    filtrePropriete(e.target.id);
  };

  const getLengthPropriete = (libelle) => {
    const lengthPropriete = propriete.filter((item) =>
      item?.commune?.ville?.libelle.includes(libelle)
    );
    return lengthPropriete.length;
  };

  return (
    <div className="my-body">
      {
        isSpinner && <Loading />
      }
      
      <Toast isVisibleToast={isToast}/>
        <Header />  
      <Tabs>
          <Tabs.Tab title='Immobiliers' key='Immobiliers'>
          <Modal
        title="Filtre"
        footer={false}
        style={{
          top: 10,
        }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <section className="px-3 mt-3">
          <label htmlFor="piece" className="h6 fw-bold">
            Nombre de pièces
          </label>
          <select
            name="piece"
            id="piece"
            className="selected"
            defaultValue={filterProp.pieceFilter}
            onChange={(e) => {
              setFilterProp({ ...filterProp, pieceFilter: e.target.value });
            }}
          >
            <option
              value=""
              selected
              onChange={(e) => setValuePrixMax(e.target.value)}
            >
              Choisir
            </option>
            <option value="1">Studio</option>
            <option value="2">2 pièces</option>
            <option value="3">3 pièces</option>
            <option value="4">4 pièces</option>
          </select>
          <div className="mt-3">
            <label htmlFor="piece" className="h6 fw-bold">
              Prix
            </label>
            <Slider
              max={maxSlider}
              min={minSlider}
              popover={true}
              range
              onAfterChange={toastValue}
              onChange={(value) => {
                console.log(value);
              }}
            />
            <div className="d-flex gap-2 justify-content-between align-items-center p-2">
              <div>
                <label htmlFor="Min">Min (Fcfa)</label>
                <input
                  type="text"
                  disabled
                  value={valuePrixMin}
                  onChange={(e) => setValuePrixMin(e.target.value)}
                  className="w-100 p-1 rounded-0 fw-bold"
                />
              </div>
              <div className="text-end">
                <label htmlFor="Max">Max (Fcfa)</label>
                <input
                  type="text"
                  disabled
                  value={valuePrixMax}
                  onChange={(e) => setValuePrixMax(e.target.value)}
                  className="w-100 p-1 rounded-0 fw-bold"
                />
              </div>
            </div>
          </div>

          <div>
            <button
              className="w-100 my-3 btn text-light fw-bold d-flex justify-content-center"
              style={{ background: "crimson" }}
              onClick={getResultat}
            >
             {!isLoadingSearch ? `Voir le resultat (${propriete.length})`: <SpinLoading 
             style={{'--size': '28px','--color':'white'}} />} 
            </button>
          </div>
        </section>
      </Modal>
      <section className="section-ville">
        <div className="conteneur-ville">
          {villes.length > 0 && (
            <div
              className="d-flex ecriteau-ville justify-content-between
           align-items-center p-2"
              style={{
                background: !getVilleSearch ? "black" : "white",
                color: !getVilleSearch ? "white" : "black",
              }}
            >
              <span className="px-3 fw-bold" onClick={getLibelleVille}>
                Toutes({propriete.length})
              </span>
            </div>
          )}
          {villes.map((item) => {
            return (
              <div
                key={item.id}
                className="d-flex
                 ecriteau-ville 
                 justify-content-between 
                 align-items-center p-2"
                style={{
                  background:
                    getVilleSearch === item.libelle ? "black" : "white",
                  color: getVilleSearch === item.libelle ? "white" : "black",
                }}
              >
                <span
                  className="px-3 fw-bold"
                  id={item.libelle}
                  onClick={getLibelleVille}
                >
                  {item.libelle}({getLengthPropriete(item.libelle)})
                </span>
              </div>
            );
          })}
        </div>
      </section>
      {propriete.length > 1 &&  <section className="text-end my-3 px-3">
        <button
          className="btn text-light bg-dark rounded-0 px-4"
          onClick={() => setIsModalOpen(true)}
        >
          <HiAdjustmentsHorizontal />
          Filtrer
        </button>
      </section> }
          <AffichePropriete
        isLoading={isLoading}
        lien={lien}
        getVilleSearch={getVilleSearch}
        propriete={propriete}
      />
          </Tabs.Tab>
          <Tabs.Tab title='Produits' key='Produits'>
          <HomeProducts />
          </Tabs.Tab>
        </Tabs>
      
    </div>
  );
}

export default Home;
