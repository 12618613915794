import { createSlice } from "@reduxjs/toolkit";



export const LoadingReducer = createSlice({
  name: "loading",
  initialState: { 
    isSpinnerVisible:false,
   },
  reducers: {
    switchIsSpinnerVisible:(state,action)=>{
        return { ...state, isSpinnerVisible: action.payload };
      },
   
  },
});

export const {  switchIsSpinnerVisible } =
  LoadingReducer.actions;
export default LoadingReducer.reducer;
