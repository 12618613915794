import React from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'antd';
import {lienImage} from "../../api/api"
import {Help} from "../../utils/utils"
const contentStyle = {
  margin: 0,
  minWidth:"300px",
  height: '500px',
  color: '#fff',
  lineHeight: '500px',
  textAlign: 'center',
  background: '#364d79',
};

export default function DetailsProduct() {
  const infoDetailProduct = useSelector(state=>state.product.infoDetailProduct)

  const handleClick = (phoneNumber,message) => {
    // Création du lien WhatsApp avec le message et le numéro
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    // Ouvrir le lien dans une nouvelle fenêtre/onglet
    window.open(url, '_blank');
};


  if(!infoDetailProduct) return <h1>Chargement...</h1>
  return (
    <section style={{display:'flex',
      alignItems:"flex-start",
      flexWrap:"wrap",
     gap:"1em"}}>
      <div className='card img_card_description'>
      <Carousel infinite={true}
                    draggable>
        {infoDetailProduct.photos.map((photo,index)=>(
          <div key={index}>
          <img 
          style={contentStyle} 
          src={lienImage+photo.path}
         alt={photo.path}
          />
          </div>
        ))}
    </Carousel>
      </div>
      <div className='card info_card_description'>
      <h1>{Help.getPrice(infoDetailProduct.prix)} Fcfa</h1>
      <h2 className='my-3'>{infoDetailProduct.libelle}</h2>
      <span className='my-3'> <span>Catégorie :</span> {infoDetailProduct.categorie.libelle}</span>
      <p className='p-0 m-0 my-3'>Description:</p>
      <h5 className='p-0 m-0'>{infoDetailProduct.description}</h5>
       <div style={{display:'flex',justifyContent:'flex-end'}}>
        <button className='btn-lg  px-5 p-2 my-3'  
        style={{background:'transparent'}}
        onClick={()=>handleClick(`+2250${Number(infoDetailProduct.numero)}`,
          `Bonjour, je souhaite en savoir plus sur votre produit : ${infoDetailProduct.libelle} qui coute : ${Help.getPrice(infoDetailProduct.prix)} Fcfa
          `)}
        >Je le veux</button>
       </div>
       </div>
     </section> 
  );
}

