import { createSlice } from "@reduxjs/toolkit";



export const ModalReducer = createSlice({
  name: "modal",
  initialState: { 
    isAddProduct:false,
    visible:false,
   },
  reducers: {
    switchVisible:(state,action)=>{
        return { ...state, visible: action.payload };
      },
    switchIsAddProduct:(state,action)=>{
      return { ...state, isAddProduct: action.payload };
    }
  },
});

export const {  switchIsAddProduct,switchVisible } =
  ModalReducer.actions;
export default ModalReducer.reducer;
