import React, { useEffect, useState } from 'react';

export default function Toast({ isVisibleToast }) {
    const [isVisible, setIsVisible] = useState(isVisibleToast);

    useEffect(() => {
        let timer;
        if (isVisibleToast) {
            setIsVisible(true);
            // Masquer le toast après 3 secondes
            timer = setTimeout(() => {
                setIsVisible(false);
            }, 3000);
        }
        return () => clearTimeout(timer); // Nettoyage du timeout lors du démontage
    }, [isVisibleToast]);

    const mystyle = {
        position: 'fixed',
        top: "10px",
        right: "5px",
        border: "1px solid green",
        borderRadius: "5px",
        zIndex: 9999999999,
        background: "rgb(43 77 43 / 59%)",
        borderLeft: "10px solid green",
        color: "white",
        padding: "1em",
        minWidth: "400px",
        display: "flex",
        justifyContent: "space-between",
        gap: "10em"
    };

    return (
        <>
            {
                isVisible && (
                    <section style={mystyle}>
                        <span>Produit ajouté</span>
                    </section>
                )
            }
        </>
    );
}
