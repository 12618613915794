import axios from "axios";
export const lien = "https://backend.bonoua-ci.com/"
export  const lienImage = "https://backend.bonoua-ci.com/storage/app/public/images/"
const instance = axios.create({
  baseURL: 'https://backend.bonoua-ci.com/api',
});

// export const lien = "http://192.168.1.69:8000/"
// export  const lienImage = "http://192.168.1.69:8000/storage/images/"
// const instance = axios.create({
//   baseURL: 'http://192.168.1.69:8000/api',
// });

instance.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;