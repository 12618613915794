import { createSlice } from "@reduxjs/toolkit";
import instance from "../../api/api";
import {switchVisible} from "./ModalSlice"
import {switchIsSpinnerVisible} from './LoadingSlice'
export const fetchAllProduct = ()=>async(dispatch)=>{
  try{
    const response = await instance.get("getProduct");
    console.log("fetchAllProduct",response)
    dispatch(fullAllProduct(response.data.data))
    }catch(error){
      console.log(error)
    }
}

export const fetchInfoProduct = (payload)=>async(dispatch)=>{
  try{
    const response = await instance.get("getDetailProduct/"+payload);
    console.log("fetchInfoProduct",response)
    dispatch(fullDetailProduct(response.data.data))
    }catch(error){
      console.log(error)
    }
}

export const saveProduct = (payload)=>async(dispatch)=>{
  console.log(payload)
  dispatch(switchIsSpinnerVisible(true))
  try{
    const response = await instance.post("saveProduct",payload);
    if(response['status'] === 200){
    console.log(response)
    dispatch(switchVisible(false))
    dispatch(switchToast(true))
    dispatch(fetchAllProduct())
    dispatch(switchIsSpinnerVisible(false))
    }
  }catch(error){
    console.log(error)
    dispatch(switchIsSpinnerVisible(false))
  }
}

export const fetchAllCategorie = ()=> async(dispatch)=>{
    try{
    const response = await instance.get("getCategorieProducts");
    console.log("fetchAllCategorie",response)
    dispatch(fullAllCategory(response.data.data))
    
    }catch(error){
      console.log(error)
    }
  }

export const productReducer = createSlice({
  name: "product",
  initialState: { 
    allProduct:[],
    allCategorieProduct:[],
    isToast:false,
    infoDetailProduct:null,
  
   },
  reducers: {
    switchToast:(state,action)=>{
      return { ...state, isToast: action.payload };
    },
    fullAllProduct:(state,action)=>{
        return { ...state, allProduct: action.payload };
      },
      fullAllCategory:(state,action)=>{
        return { ...state, allCategorieProduct: action.payload };
      },
      fullDetailProduct:(state,action)=>{
        return {...state,infoDetailProduct:action.payload}
      }
  },
});

export const { fullAllProduct,fullAllCategory,switchToast,fullDetailProduct} = productReducer.actions;
export default productReducer.reducer;
