import React, { useState,useContext } from "react";
import { Tabs } from "antd-mobile";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { AuthContext } from '../context/AuthContext';
import instance from "../api/api";
import Notification from "../feature/Notification";
import { Dialog } from "antd-mobile";
function Connexion() {
    const navigate = useNavigate();
    const {login}=useContext(AuthContext);
    const [isLoading,setIsLoading]=useState(false);
    const [isMsg,setIsMsg]=useState(null);
    const [status,setStatus]=useState(null);
  const [data, setData] = useState({
    nom: "",
    prenoms: "",
    email: "",
    phone: "",
    password: "",
    role_id:2
  });

  const [data1, setData1] = useState({

    email: "",
   
    password: "",
   
  });
  const [cpassword, setCpassword] = useState("");

  const middleware = ()=>{
    let verifField = false;
    if(data.nom && data.prenoms &&
    data.email &&
    data.phone &&
    data.password){
      verifField = true
    }
    return verifField;
  }

  const register = async(e)=>{
    e.preventDefault()
    const isFieldEmpty = middleware();
    if(!isFieldEmpty){
      console.log("ERROR")
    }else{
    
      setIsLoading(true)
      if(data.password !== cpassword){
        //console.log('pas ok')
        setIsLoading(false)
      }else{
        try{
          const response = await instance.post("createUser",data)
          //console.log(response.data)
          if(response.data.status){
            Dialog.alert({
              content: 'Sucess',
               confirmText:"OK"
            })
            setIsLoading(false)
          } 
          }catch(error){
           console.log(error)
           setStatus(error.response.data.status)
           setIsMsg(error.response.data.message)
           setIsLoading(false)
          }
      }
    }
   
   
  }

  const seConnecter = async(e)=>{
    e.preventDefault()
    if(!data1.email || !data1.password){
      console.log("ERROR")
    }else{
      setIsLoading(true)
      try{
        const response = await instance.post("login",data1)
        //console.log(response.data)
        login(response.data.data,response.data.access_token)
        if(response.data.status){
          setIsLoading(false)
            Dialog.alert({
            content: 'Connecté',
            confirmText:"OK"
             })
         navigate("/")
         
        }
       
        }catch(error){
         //console.log(error)
         setStatus(error.response.data.status)
         setIsMsg(error.response.data.message)
         setIsLoading(false)
        }
    }
  
  }

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.Tab title="Créer votre compte" key="1">
        <div className="d-flex align-items-center  my-3">
        <div className="me-4 connexion_back_home">
          <NavLink to="/">
            <ArrowBackIosNewIcon style={{
              color:"black",
              fontWeight:"bold",

            }} />
          </NavLink>
        </div>
        <h3 className="m-0 p-0">Fournisseur</h3>
        <Notification status={status} message={isMsg} />
        </div>
          <form onSubmit={register}>
            <input
              type="text"
              value={data.nom}
              placeholder="Nom"
              className="my-2"
              onChange={(e) => {
                setData({ ...data, nom: e.target.value });
              }}
            />
            <input
              type="text"
              value={data.prenoms}
              placeholder="Prénoms"
              className="my-2"
              onChange={(e) => {
                setData({ ...data, prenoms: e.target.value });
              }}
            />
            <input
              type="email"
              value={data.email}
              placeholder="Email"
              className="my-2"
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
            <input
              type="text"
              value={data.phone}
              placeholder="Contact"
              className="my-2"
              onChange={(e) => {
                setData({ ...data, phone: e.target.value });
              }}
            />
            <input
              type="password"
              value={data.password}
              placeholder="mot de passe"
              className="my-2"
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
            />
            <input
              type="password"
              value={cpassword}
              placeholder="confirmation du mot de passe"
              className="my-2"
              onChange={(e) => {
                setCpassword(e.target.value);
              }}
            />
            <div className="d-flex justify-content-center align-items-center">
              <button 
              disabled={isLoading}
              className="btn-lg bg-dark text-light p-3 rounded-4 border-0 mx-2">
                Enregistrer
              </button>
              <button  className="btn-lg bg-secondary text-light py-3 px-4 rounded-4 border-0 mx-2" onClick={(e)=>{
                e.preventDefault();
                navigate("/")
              }}>
                Retour
              </button>
            </div>
          </form>
        </Tabs.Tab>
        <Tabs.Tab title="Se connecter" key="2">
          <Notification status={status} message={isMsg} />
          <div className="d-flex align-items-center  my-3">
        <div className="me-4 connexion_back_home">
          <NavLink to="/">
            <ArrowBackIosNewIcon style={{
              color:"black",
              fontWeight:"bold",
              
            }}  />
          </NavLink>
        </div>
        <h3 className="m-0 p-0">Se connecter</h3>
        </div>
          <form onSubmit={seConnecter}>
            <input
              type="email"
              value={data1.email}
              placeholder="Email"
              className="my-2"
              onChange={(e) => {
                setData1({ ...data1, email: e.target.value });
              }}
            />
            <input
              type="password"
              value={data1.password}
              placeholder="Mot de passe"
              className="my-2"
              onChange={(e) => {
                setData1({ ...data1, password: e.target.value });
              }}
            />
            <div className="d-flex justify-content-center align-items-center">
              <button 
              disabled={isLoading}
              className="btn-lg bg-dark text-light p-3 rounded-4 border-0 mx-2">
                {isLoading ? 'Chargement...':'Connexion'}
              </button>
              <button className="btn-lg bg-secondary text-light py-3 px-4 rounded-4 border-0 mx-2" onClick={(e)=>{
                e.preventDefault();
                navigate("/")
              }}>
                Retour
              </button>
            </div>
          </form>
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}

export default Connexion;
