import React ,{useState,useEffect }from 'react';
import { InputNumber } from 'primereact/inputnumber';
import {useSelector,useDispatch} from "react-redux";
import {fetchAllCategorie,saveProduct} from  "../../store/slice/ProductSlice";
// import Compressor from 'compressorjs';

// let elementTableau = [];
import {  Input } from 'antd';

export default function SaveProduct() {
  const dispatch = useDispatch();
  const categories = useSelector(state=>state.product.allCategorieProduct)
  const { TextArea } = Input;
    const [data,setData]=useState({
      libelle:"",
      description:"",
      prix:"",
      categories_id:"",
      numero:"",
    });
    const [path,setPath]=useState([]);
   

    
    const handleSubmit = (e)=>{
     
      e.preventDefault()
      console.log(path)
      const formData = new FormData();
      for (const item of path) {
        formData.append("path[]", item);
      }
      formData.append("libelle", data.libelle);
      formData.append("description", data.description);
      formData.append("prix", data.prix);
      formData.append("categories_id", data.categories_id);
      formData.append("numero", data.numero);
   
      dispatch(saveProduct(formData))
    
     
      // console.log('payload',formData)
    }

    // const tansFormFile=(tableau)=>{
    //   const elemnt = []
    //  tableau.forEach(item=>{
    
    //   new Compressor(item, {
    //     quality: 0.6,
    //     file:File,
    //     mimeType: 'image/webp',
    //     success: (webpFile) => {
    //       console.log(typeof webpFile)
    //       elemnt.push(webpFile)
    //     },
    //   });
  
    //  })
    //  return elemnt;
    // //  console.log(elemnt)
    // //  setPath(elementTableau)
    // }
   

    const handleFileArrayUpload=(event)=>{
      
      const files = event.target.files;
      // console.log("handleFileArrayUpload",Array.from(files))
      if (!files || files.length === 0) return;
      if (files.length > 3) {
        alert("Veuillez sélectionner un maximum de 3 images.");
        
        // Réinitialiser le champ de fichier pour forcer une nouvelle sélection
        event.target.value = "";
        return;
      }
      setPath(Array.from(files))
    //  const tableauConvert = tansFormFile(Array.from(files))
    //  console.log("tableauConvert",tableauConvert)

      // Array.from(files).forEach((file) => {
      //   new Compressor(file, {
      //     quality: 0.6,
      //     mimeType: 'image/webp',
      //     success: (webpFile) => {
      //       setPath(path=>[...path,webpFile])  
      //     },
      //     error: (err) => {
      //       console.error('Erreur de conversion:', err.message);
      //     },
      //   });
      // });
    };
  
   
    useEffect(()=>{
      dispatch(fetchAllCategorie())
    },[dispatch])
  return (
    <>
    
    <form >
        <section>
        <label htmlFor="file" className='my-3'>Ajouter des photos</label>
        <input type="file"
        accept="image/*" 
        value={data.path}
        onChange={handleFileArrayUpload}
        multiple/>
        </section>
        <section>
        <label htmlFor="categorie" className='my-3'>Catégorie de produits</label>
        <select name="categorie"
        defaultValue={data.categories_id}
        id="categorie"
        onChange={(e)=>{
          console.log("e.target.value",e.target.value)
          setData({...data,categories_id:e.target.value})
        }}
        >
          <option value="">Choisir une catégorie</option>
          {categories.map((item,index)=>(
          <option key={index} value={item.id}>{item.libelle}</option>
          ))}
         
        </select>
        </section>
        <section>
        <label htmlFor="name" className='my-3'>Nom du produit</label>
        <input type="text" 
        value={data.libelle}
        onChange={(e)=>{
          console.log("e.target.value",e.target.value)
          setData({...data,libelle:e.target.value})
        }}
        />
        </section>
        <section>
        <label htmlFor="desc" className='my-3'>Description</label>
        <TextArea
      showCount
      maxLength={100}
      onChange={(e)=>{
        console.log("e.target.e",e.target.value)
        setData({...data,description:e.target.value})
      }}
      placeholder="Description"
      style={{
        height: 120,
        resize: 'none',
        border:"1px solid black"
      }}
    />
        {/* <textarea id="story"
         value={data.description}
         onChange={(e)=>{
           console.log("e.target.value",e.target.value)
           setData({...data,description:e.target.value})
         }}
        name="story" rows="9" cols="33" style={{width:"100%"}}>

       </textarea> */}
       
        </section>
        <section>
        <label htmlFor="name" className='my-3'>Prix (fcfa)</label>
       
                <InputNumber 
                inputId="integeronly" 
                style={{width:"100%"}} 
                value={data.prix} 
                onValueChange={(e) => setData({...data,prix:e.value})} />
       
        </section>
        <section>
        <label htmlFor="name" className='my-3'>Numéro whatsapp</label>
        <input type="text" inputMode="numeric" 
        pattern="\d{10}" 
        value={data.numero}
         onChange={(e)=>{
           console.log("e.target.value",e.target.value)
           setData({...data,numero:e.target.value})
         }}
        title="Veuillez entrer un numéro de 10 chiffres"
         placeholder="Numéro de téléphone" required />
        </section>
        <section className='d-flex justify-content-center my-4'>
        <button 
            onClick={handleSubmit}
            className='btn-lg bg-dark text-light' 
            style={{padding:".8em"}}
            >Valider</button> 
        </section>
        
    </form>
    </>
  );
}
