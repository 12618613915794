import React,{useEffect} from 'react';

import DialogProduct from "./DialogProduct"
import SaveProduct from "./SaveProduct";
import DetailsProduct from "./DetailsProduct";
import { useSelector, useDispatch } from "react-redux";
import {switchIsAddProduct,switchVisible} from "../../store/slice/ModalSlice";
import {fetchAllProduct,fetchInfoProduct} from "../../store/slice/ProductSlice";
import {Help} from "../../utils/utils"
import { Carousel } from 'antd';
import {lienImage} from "../../api/api"
const contentStyle = {
  margin: 0,
  height: '350px',
  color: '#fff',
  lineHeight: '350px',
  textAlign: 'center',
  background: '#364d79',
};

export default function HomeProducts() {
  const dispatch = useDispatch();
  const isAddProduct = useSelector(
    (state) => state.Modal.isAddProduct
  );
  const allProduct =  useSelector(
    (state) => state.product.allProduct
  );
 

  useEffect(()=>{
    dispatch(fetchAllProduct())
  },[])
  return (
    <>

     
    {isAddProduct === "Add product" &&  
    <DialogProduct libelleHeader={"Ajouter un produit+"}>
      <SaveProduct />
    </DialogProduct>
    }

    {isAddProduct === "Detail product" && 
     <DialogProduct libelleHeader={"Detail du produit"}>
     <DetailsProduct />
   </DialogProduct>
    }
    
    <section className='d-flex my-2' style={{justifyContent:"flex-end"}}>
    <button className='btn-lg bg-dark text-light'
     style={{padding:".8em"}}
     onClick={()=>{
      dispatch(switchVisible(true))
      dispatch(switchIsAddProduct("Add product"))
    }}
     >Ajouter un produit+</button>
      </section>
    <section style={{
    display: "flex",
    justifyContent: "center",
    placeContent: "center",
    alignItems: "center",
    gap: "1.5em",
    flexWrap: "wrap",
}}> 
{
  allProduct.map((item,index)=>(
    <CardProduits key={index} item={item} />
  ))
}
   
   
    </section>
    
    </>
  );
}

const CardProduits=({item})=>{
  const dispatch = useDispatch();
return(
<section
style={{
  width: "350px",
  height: "auto",
  boxShadow: "6px 6px 6px 6px rgba(0, 0, 0, 0.118)",
}}

>
<div className='conteneur_image'> 
<Carousel infinite={true} autoplay
                    draggable>
        {item.photos.map((photo,index)=>(
          <div key={index}>
          <img 
          style={contentStyle} 
          src={lienImage+photo.path}
         alt={photo.path}
          />
          </div>
        ))}
    </Carousel>
   
</div>  
<div style={{
    padding:"0.5em 1.5em",
    cursor:'pointer',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    gap:"2em"
}}
onClick={()=>{
  dispatch(switchVisible(true))
  dispatch(switchIsAddProduct("Detail product"))
  dispatch(fetchInfoProduct(item.id))
}}
>
    <div>
    <h5>{item.libelle}</h5>
    <span style={{color:'teal'}}>{item.categorie.libelle}</span>
    </div>
    <div>{Help.getPrice(Number(item.prix))} Fcfa</div>
</div> 
<div className='px-3'>
<button className='w-100 btn-lg my-4 p-2 btn_survole_color'
onClick={()=>{
  dispatch(switchVisible(true))
  dispatch(switchIsAddProduct("Detail product"))
  dispatch(fetchInfoProduct(item.id))
}}
>Voir plus</button>  
</div> 

</section>
)
}


